<template>
  <div class="page px-4 px-md-4 pt-4">
    <div class="d-flex px-0 px-md-4 pb-4 align-center justify-space-between">
      <span class="font-weight-bold">
        Packaging Manager
      </span>
      <span class="caption-small">
        Home > Packaging Manager
      </span>
    </div>
    <v-row no-gutters class="mb-4 px-0 px-sm-4 pb-4">
      <v-col cols="12" class="col-sm-3 d-flex align-center">
        <v-text-field
          v-model="search"
          label="Search"
          outlined
          append-icon="mdi-magnify"
          block
          dense
          class="rounded-pill"
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
          autocomplete="off"
          hide-details
        />
      </v-col>
      <v-col cols="12" class="col-sm-3 px-0 px-sm-4 pt-2 pt-sm-0">
        <v-menu
          ref="menu"
          v-model="menu"
          :return-value.sync="dates"
          left
          offset-y
          :close-on-content-click="false"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              label="Date Ranges"
              append-icon="mdi-calendar-outline"
              readonly
              outlined
              dense
              hide-details
              clearable
              :color="$vuetify.theme.dark ? 'grey' : 'primary'"
              class="rounded-pill"
              v-bind="attrs"
              v-on="on"
              @click:clear="dates = []"
            />
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            light
            class="force-light-font"
            range
          >
            <v-spacer />
            <v-btn
              text
              class="text-capitalize"
              small
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              class="text-capitalize"
              small
              @click="$refs.menu.save(dates)"
            >
              Ok
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" class="col-sm-2 pb-2 pb-sm-0 pr-0 pr-sm-3 pt-2 pt-sm-0">
        <v-select
          v-model.number="filterHistoryType"
          :items="[{ value: 1, name: 'Stock In' }, { value: 2, name: 'Stock Out' }, { value: 0, name: 'All Type' }]"
          item-text="name"
          item-value="value"
          :name="Math.random()"
          placeholder="Type"
          outlined
          autocomplete="off"
          dense
          class="rounded-pill"
          hide-details
          :color="$vuetify.theme.dark ? 'grey' : 'primary'"
        />
      </v-col>
      <v-col cols="6" class="col-sm-2 pr-1 pt-2 pt-sm-0">
        <v-btn depressed color="indigo" dark block class="text-capitalize rounded-pill caption-small font-weight-bold text-capitalize" @click.prevent="PackagingHistoryView()">
          <v-icon class="mr-1" small>
            mdi-plus
          </v-icon>
          Create New
        </v-btn>
      </v-col>
      <v-col cols="6" class="col-sm-2 pl-1 pt-2 pt-sm-0">
        <v-btn depressed color="primary" dark block class="text-capitalize rounded-pill caption-small font-weight-bold text-capitalize" @click.prevent="OpenPackagingList()">
          <v-icon class="mr-1" small>
            mdi-gift
          </v-icon>
          Packaging List
        </v-btn>
      </v-col>
    </v-row>
    <div class="px-0 px-sm-4">
      <v-data-table
        dense
        :headers="headers"
        :items="table"
        item-key="id"
        :server-items-length="tableTotal"
        :page.sync="tablePage"
        :items-per-page.sync="tableLimit"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100]
        }"
        :loading="tableLoading"
        class="accent"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="caption-small py-1">
            #{{ item.id }}
          </div>
        </template>
        <template v-slot:[`item.title`]="{ item }">
          <div class="caption-small py-1">
            {{ item.title }}
          </div>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <div class="caption-small py-1">
            <v-chip :color="parseInt(item.type) === 1 ? 'blue' : ((parseInt(item.type) === 2) ? 'red' : 'Undefined')" small dark>
              {{ parseInt(item.type) === 1 ? 'Stock In' : ((parseInt(item.type) === 2) ? 'Stock Out' : 'Undefined') }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <div class="caption-small py-1">
            {{ $localDT(item.created, 'datetimedefault') }}
          </div>
        </template>
        <template v-slot:[`item.items`]="{ item }">
          <div class="caption-small py-1">
            <span v-if="!ParsePackagingItems(item.items).length" class="caption-small">
              no items
            </span>
            <v-chip v-for="(ii, iiItem) in ParsePackagingItems(item.items)" :key="'item-key-' + item.id + '-' + iiItem" small class="caption-small mr-1">
              {{ ii.packaging_name }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.summary`]="{ item }">
          <div class="caption-small py-1 text-right">
            {{ PackagingItemsSummary(ParsePackagingItems(item.items)).price | price }} / {{ + PackagingItemsSummary(ParsePackagingItems(item.items)).qty }} item(s)
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center caption-small py-2">
            <v-btn x-small outlined color="orange" class="rounded-pill text-capitalize" @click.prevent="PackagingHistoryView(item)">
              <v-icon small color="orange" class="mr-2">
                mdi-eye
              </v-icon>
              View
            </v-btn>
          </div>
        </template>
        <template v-slot:footer>
          <div class="pt-2 pb-3 px-4 px-md-8">
            <v-divider class="mt-2 mb-3" />
            <div class="d-flex justify-space-between flex-column flex-sm-row">
              <div class="d-flex align-center justify-center justify-sm-start font-weight-bold">
                Summary Reports :
              </div>
              <div v-if="tableSummary" class="d-flex justify-center align-sm-end flex-column body-2">
                <div v-if="!parseInt(filterHistoryType) || parseInt(filterHistoryType) === 1" class="font-weight-bold caption-small">
                  Stock In : {{ tableSummary.in.total | price }} / {{ tableSummary.in.qty }} item(s)
                </div>
                <div v-if="!parseInt(filterHistoryType) || parseInt(filterHistoryType) === 2" class="font-weight-bold caption-small">
                  Stock Out : {{ tableSummary.out.total | price }} / {{ tableSummary.out.qty }} item(s)
                </div>
                <div v-if="!parseInt(filterHistoryType)" class="font-weight-bold caption text-uppercase">
                  Actual Stock : {{ (parseInt(tableSummary.in.total) - parseInt(tableSummary.out.total)) | price }} / {{ (parseInt(tableSummary.in.qty) - parseInt(tableSummary.out.qty)) }} item(s)
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="dialogPackagingHistory.show"
      scrollable
      max-width="420px"
    >
      <v-card v-if="dialogPackagingHistory.data" light>
        <v-card-title class="body-2 font-weight-bold">
          <v-icon class="mr-2" small>
            mdi-gift
          </v-icon>
          {{ dialogPackagingHistory.data.id ? 'Update Packaging History' : 'New Packaging History' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="py-7">
          <v-select
            v-model.number="dialogPackagingHistory.data.type"
            :items="[{ value: 1, name: 'Stock In' }, { value: 2, name: 'Stock Out' }, { value: 0, name: 'Not Selected' }]"
            item-text="name"
            item-value="value"
            :name="Math.random()"
            placeholder="Type"
            outlined
            autocomplete="off"
            dense
            hide-details
            :class="parseInt(dialogPackagingHistory.data.type) === 1 ? 'blue' : (parseInt(dialogPackagingHistory.data.type) === 2) ? 'red' : 'grey'"
            dark
            :color="$vuetify.theme.dark ? 'grey' : 'primary'"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogPackagingHistory.data.title"
            label="Title"
            outlined
            block
            dense
            class="mb-4"
            autocomplete="off"
            hide-details
          />
          <v-textarea
            v-model="dialogPackagingHistory.data.description"
            label="Description"
            outlined
            block
            dense
            autocomplete="off"
            class="mb-4"
            hide-details
            rows="2"
          />
          <!-- <v-select
            v-model.number="dialogPackagingHistory.data.status"
            :items="[{ value: 1, name: 'Active' }, { value: 0, name: 'Not Active' }]"
            item-text="name"
            item-value="value"
            :name="Math.random()"
            label="Status"
            outlined
            autocomplete="off"
            dense
            hide-details
            :color="$vuetify.theme.dark ? 'grey' : 'primary'"
            class="mb-4"
          /> -->
          <v-divider class="mb-2" />
          <div>
            Items :
            <v-autocomplete
              v-model="itemSelected"
              :items="itemList"
              :name="Math.random()"
              :search-input.sync="itemQuery"
              label="Add Packaging Item"
              item-text="name"
              return-object
              outlined
              clearable
              autocomplete="off"
              dense
              class="my-4"
              hide-details
            >
              <template v-slot:append-outer>
                <v-icon
                  v-text="'mdi-plus'"
                  color="success"
                  @click.prevent="PackagingView()"
                />
              </template>
            </v-autocomplete>
            <div v-if="!dialogPackagingHistory.data.items.length" class="caption-small text-center red--text">
              no items
            </div>
            <div v-for="(i, iItem) in dialogPackagingHistory.data.items" :key="'packaging-item-' + iItem" class="caption font-weight-bold d-flex justify-space-between align-center fill-width px-2 py-1 grey lighten-3 rounded-lg mb-1">
              <div>
                {{ iItem + 1 }}. {{ i.packaging_name }}
              </div>
              <div class="d-flex align-center">
                <div class="d-flex align-center mr-2">
                  <input
                    v-model.number="dialogPackagingHistory.data.items[iItem].quantity"
                    type="number"
                    class="px-2 py-1 grey lighten-4 rounded-lg text-right"
                    style="max-width:55px;outline: none;"
                    @keyup="parseInt(dialogPackagingHistory.data.items[iItem].quantity ) ? '' : (dialogPackagingHistory.data.items[iItem].quantity = 1)"
                    @change="parseInt(dialogPackagingHistory.data.items[iItem].quantity ) ? '' : (dialogPackagingHistory.data.items[iItem].quantity = 1)"
                  >
                </div>
                <v-icon small color="red" @click.prevent="PackagingItemDelete(iItem, i.id)">
                  mdi-close
                </v-icon>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red"
            text
            small
            dark
            class="text-capitalize"
            @click="dialogPackagingHistory.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogPackagingHistory.data"
            depressed
            color="purple"
            dark
            small
            class="rounded-pill px-8 caption font-weight-bold text-capitalize"
            @click="PackagingHistoryProcess()"
          >
            {{ dialogPackagingHistory.data.id ? 'Update' : 'Create New' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPackagingList"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="rounded-0" flat>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>
            <div class="caption font-weight-bold">
              Packaging List Items
            </div>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="dialogPackagingList = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div style="max-height: calc(100vh - 64px);overflow-y: auto;">
          <v-row no-gutters class="mb-4 px-0 px-sm-4 pt-4">
            <v-col cols="12" class="col-sm-9 d-flex align-center">
              <v-text-field
                v-model="searchPackaging"
                label="Search"
                outlined
                append-icon="mdi-magnify"
                block
                dense
                class="rounded-pill"
                :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                autocomplete="off"
                hide-details
              />
            </v-col>
            <!-- <v-col cols="6" class="col-sm-3 px-0 px-sm-4">
              <v-menu
                ref="menuPackaging"
                v-model="menuPackaging"
                :return-value.sync="datesPackaging"
                left
                offset-y
                :close-on-content-click="false"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateRangeTextPackaging"
                    label="Date Ranges"
                    append-icon="mdi-calendar-outline"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                    class="rounded-pill"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="datesPackaging = []"
                  />
                </template>
                <v-date-picker
                  v-model="datesPackaging"
                  no-title
                  light
                  class="force-light-font"
                  range
                >
                  <v-spacer />
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="menuPackaging = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="$refs.menuPackaging.save(datesPackaging)"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col> -->
            <v-col cols="6" class="col-sm-3">
              <v-btn depressed color="primary" dark block class="text-capitalize rounded-pill caption-small font-weight-bold text-capitalize ml-2" @click.prevent="PackagingView()">
                <v-icon class="mr-1" small>
                  mdi-plus
                </v-icon>
                Create New
              </v-btn>
            </v-col>
          </v-row>
          <div class="px-0 px-sm-4">
            <v-data-table
              dense
              :headers="headersPackaging"
              :items="tablePackaging"
              item-key="id"
              :server-items-length="tableTotalPackaging"
              :page.sync="tablePagePackaging"
              :items-per-page.sync="tableLimitPackaging"
              :options.sync="optionsPackaging"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100]
              }"
              :loading="tableLoadingPackaging"
              class="accent"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.name`]="{ item }">
                <div class="caption-small py-1">
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:[`item.stock_in`]="{ item }">
                <div class="caption-small py-1">
                  {{ item.stock_in }}
                </div>
              </template>
              <template v-slot:[`item.stock_out`]="{ item }">
                <div class="caption-small py-1">
                  {{ item.stock_out }}
                </div>
              </template>
              <template v-slot:[`item.stock_actual`]="{ item }">
                <div class="caption-small py-1">
                  {{ parseInt(item.stock_in) - parseInt(item.stock_out) }}
                </div>
              </template>
              <template v-slot:[`item.price`]="{ item }">
                <div class="caption-small py-1 text-right">
                  {{ item.price | price }}
                </div>
              </template>
              <template v-slot:[`item.value`]="{ item }">
                <div class="caption-small py-1 text-right">
                  {{ parseInt(item.price) * (parseInt(item.stock_in) - parseInt(item.stock_out)) | price }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex align-center justify-center caption-small py-2">
                  <v-btn x-small depressed color="orange" dark class="rounded-pill text-capitalize" @click.prevent="PackagingView(item)">
                    <v-icon small class="mr-2">
                      mdi-eye
                    </v-icon>
                    View
                  </v-btn>
                </div>
              </template>
              <template v-slot:footer>
                <div class="pt-2 pb-3 px-4 px-md-8">
                  <v-divider class="mt-2 mb-3" />
                  <div class="d-flex justify-space-between flex-column flex-sm-row">
                    <div class="d-flex align-center justify-center justify-sm-start font-weight-bold">
                      Summary Reports :
                    </div>
                    <div v-if="tableSummaryPackaging" class="d-flex justify-center align-sm-end flex-column body-2">
                      <div class="font-weight-bold caption-small">
                        Stock In : {{ tableSummaryPackaging.in.total | price }} / {{ tableSummaryPackaging.in.qty }} item(s)
                      </div>
                      <div class="font-weight-bold caption-small">
                        Stock Out : {{ tableSummaryPackaging.out.total | price }} / {{ tableSummaryPackaging.out.qty }} item(s)
                      </div>
                      <div class="font-weight-bold caption text-uppercase">
                        Actual Stock : {{ (parseInt(tableSummaryPackaging.in.total) - parseInt(tableSummaryPackaging.out.total)) | price }} / {{ (parseInt(tableSummaryPackaging.in.qty) - parseInt(tableSummaryPackaging.out.qty)) }} item(s)
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <template v-if="dialogPackaging.show">
      <v-dialog
        v-model="dialogPackaging.show"
        scrollable
        max-width="375px"
      >
        <v-card v-if="dialogPackaging.data" light>
          <v-card-title class="body-2 font-weight-bold">
            <v-icon class="mr-2" small>
              mdi-plus
            </v-icon>
            {{ dialogPackaging.data.id ? 'Update Packaging' : 'Create New Packaging' }}
          </v-card-title>
          <v-divider />
          <v-card-text class="py-7">
            <v-text-field
              v-model="dialogPackaging.data.name"
              label="Name"
              outlined
              block
              dense
              class="mb-4"
              autocomplete="off"
              hide-details
            />
            <v-textarea
              v-model="dialogPackaging.data.description"
              label="Description"
              outlined
              block
              dense
              autocomplete="off"
              hide-details
              class="mb-4"
              rows="2"
            />
            <Currency
              v-model.number="dialogPackaging.data.price"
              label="Price"
              class-add="mb-0"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              color="red"
              text
              small
              dark
              class="text-capitalize"
              @click="dialogPackaging.show = false"
            >
              Close
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="dialogPackaging.data"
              depressed
              color="orange"
              dark
              small
              class="rounded-pill px-8 caption font-weight-bold text-capitalize"
              @click="PackagingProcess()"
            >
              {{ dialogPackaging.data.id ? 'Update' : 'Create' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
const NewPackaging = {
  id: null,
  name: '',
  description: '',
  price: 0,
  status: 1,
  created: null
}

const NewPackagingHistory = {
  id: null,
  type: 0,
  title: '',
  description: '',
  items: [],
  status: 0,
  created: null
}

const NewPackagingHistoryItem = {
  id: null,
  packaging_history: 0,
  packaging_id: 0,
  quantity: 0,
  status: 0,
  created: null
}

let searchTimeout = null
export default {
  data: () => ({
    filterHistoryType: 0,
    itemSelected: null,
    itemList: [],
    itemQuery: '',
    menu: false,
    dates: [],
    search: '',
    tableLoading: false,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 10,
    options: {},
    headers: [
      // { text: '#ID', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Stock In / Out', value: 'type' },
      { text: 'Items', value: 'items' },
      { text: 'Summary', value: 'summary', sortable: false },
      { text: 'Created', value: 'created' },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    menuPackaging: false,
    datesPackaging: [],
    searchPackaging: '',
    tableLoadingPackaging: false,
    tablePackaging: [],
    tableTotalPackaging: 0,
    tablePagePackaging: 1,
    tableLimitPackaging: 10,
    optionsPackaging: {},
    headersPackaging: [
      // { text: '#ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Stock In', value: 'stock_in', align: 'right' },
      { text: 'Stock Out', value: 'stock_out', align: 'right' },
      { text: 'Actual Stock', align: 'right', value: 'stock_actual', sortable: false },
      { text: 'Price (Hpp)', align: 'right', value: 'price' },
      { text: 'Price Value', align: 'right', value: 'value', sortable: false },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    dialogPackaging: {
      show: false,
      data: Object.assign({}, NewPackaging)
    },
    dialogPackagingHistory: {
      show: false,
      data: Object.assign({}, NewPackagingHistory)
    },
    dialogPackagingList: false,
    tableSummary: null,
    tableSummaryPackaging: null
  }),
  watch: {
    filterHistoryType () {
      this.PackagingHistoryLoad()
    },
    search () {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PackagingHistoryLoad()
      }, 300)
    },
    dates (v) {
      if (v.length === 2 || !v.length) {
        this.PackagingHistoryLoad() 
      }
    },
    searchPackaging () {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.PackagingLoad()
      }, 300)
    },
    datesPackaging (v) {
      if (v.length === 2 || !v.length) {
        this.PackagingLoad() 
      }
    },
    itemQuery (q) {
      this.$store.dispatch('PACKAGING_GET', (q ? ('?page=1&limit=10&q=' + q) : '')).then((res) => {
        if (res.status) {
          this.itemList = res.data.data.data || []
        } else {
          this.itemList = []
        }
      })
    },
    itemSelected (v) {
      if (v) {
        const itemSelected = Object.assign({}, v)
        const existItem = this.dialogPackagingHistory.data.items.find(r => parseInt(r.packaging_id) === parseInt(itemSelected.id))
        if (existItem) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Item already exist!' })
        } else {
          this.dialogPackagingHistory.data.items.push(Object.assign({}, {
            id: null,
            packaging_history: parseInt(this.dialogPackagingHistory.data.id),
            packaging_id: parseInt(itemSelected.id),
            quantity: 1,
            packaging_name: itemSelected.name || '-',
            status: 1
          }))
        }
        this.itemSelected = null
        this.itemList = []
      }
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    dateRangeTextPackaging () {
      return this.datesPackaging.join(' ~ ')
    }
  },
  mounted () {
    this.PackagingHistoryLoad()
  },
  methods: {
    OpenPackagingList () {
      this.dialogPackagingList = true
      this.PackagingLoad()
    },
    PackagingHistoryView (item) {
      if (item) {
        item = Object.assign({}, item)
      } else {
        item = Object.assign({}, NewPackagingHistory)
      }
      if (!item.items) {
        item.items = []
      } else if (typeof item.items === 'string') {
        item.items = this.ParsePackagingItems(item.items)
      }
      this.dialogPackagingHistory.data = item
      this.dialogPackagingHistory.show = true
    },
    ParsePackagingItems (itemsString) {
      const parsedItems = []
      const items = itemsString ? itemsString.split('|-|') : null
      if (!items) {
        return parsedItems
      }
      if (items.length) {
        if (!parseInt(items[0])) {
          return parsedItems
        }
        for (let i = 0; i < items.length; i++) {
          const item = items[i].split(',')
          parsedItems.push({
            id: parseInt(item[0]) || null,
            packaging_history: parseInt(item[1]) || 0,
            packaging_id: parseInt(item[2]) || 0,
            quantity: parseInt(item[3]) || 0,
            status: parseInt(item[4]) || 0,
            packaging_price: parseInt(item[5]) || 0,
            packaging_summary: parseInt(item[6]) || 0,
            packaging_name: item[7] || '-'
          })
        }
      }
      return parsedItems
    },
    PackagingItemsSummary (items) {
      let qty = 0
      let price = 0
      for (let i = 0; i < items.length; i++) {
        qty += parseInt(items[i].quantity)
        price += parseInt(items[i].packaging_summary)
      }
      return {
        qty,
        price
      }
    },
    PackagingItemDelete (index, itemid) {
      if (parseInt(itemid)) {
        if (confirm('Delete this item ?')) {
          this.$store.dispatch('PACKAGING_HISTORY_ITEM_DELETE', itemid)
            .then((res) => {
              if (res.status) {
                this.dialogPackagingHistory.data.items.splice(index, 1)
              }
            })
        }
      } else {
        this.dialogPackagingHistory.data.items.splice(index, 1)
      }
    },
    PackagingHistoryLoad () {
      const fromTo = this.GetDates(this.dates)
      const q = this.search || ''
      console.log('q:::', q)
      const withType = parseInt(this.filterHistoryType) > 0 ? '&type=' + (parseInt(this.filterHistoryType)) : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?x=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('PACKAGING_HISTORY_GET', query + (q ? ('&q=' + q) : '') + (fromTo || '') + withType).then((res) => {
        if (res.status) {
          this.table = res.data.data.data || []
          this.tableTotal = parseInt(res.data.data.total) || 0
          this.tableSummary = Object.assign({}, res.data.data.summary)
        } else {
          this.table = []
          this.tableTotal = 0
          this.tableSummary = null
        }
        this.tableLoading = false
      })
    },
    PackagingHistoryProcess () {
      if (!parseInt(this.dialogPackagingHistory.data.type)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select type (Stock In / Out)!' })
        return false
      }
      if (!this.dialogPackagingHistory.data.title) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input title!' })
        return false
      }
      if (!this.dialogPackagingHistory.data.description) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input description!' })
        return false
      }
      if (!this.dialogPackagingHistory.data.items.length) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please add one or more item!' })
        return false
      }
      this.$store.dispatch('PACKAGING_HISTORY_PROCESS', this.dialogPackagingHistory.data)
        .then((res) => {
          if (res.status) {
            this.dialogPackagingHistory.show = false
            this.$store.commit('SNACKBAR', { show: true, text: this.dialogPackagingHistory.data.id ? 'Updated!' : 'Created!' })
            this.PackagingHistoryLoad()
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong!' })
          }
        })
    },
    PackagingView (item) {
      if (item) {
        item = Object.assign({}, item)
      } else {
        item = Object.assign({}, NewPackaging)
      }
      this.dialogPackaging.data = item
      this.dialogPackaging.show = true
    },
    PackagingLoad () {
      const fromTo = this.GetDates(this.datesPackaging)
      const q = this.searchPackaging || ''
      this.tableLoadingPackaging = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsPackaging
      const sorting = sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : ''
      const query = '?x=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + sorting
      this.$store.dispatch('PACKAGING_GET', query + (q ? ('&q=' + q) : '') + (fromTo || '')).then((res) => {
        if (res.status) {
          this.tablePackaging = res.data.data.data || []
          this.tableTotalPackaging = parseInt(res.data.data.total) || 0
          this.tableSummaryPackaging = Object.assign({}, res.data.data.summary)
        } else {
          this.tablePackaging = []
          this.tableTotalPackaging = 0
          this.tableSummaryPackaging = null
        }
        this.tableLoadingPackaging = false
      })
    },
    PackagingProcess () {
      this.$store.dispatch('PACKAGING_PROCESS', this.dialogPackaging.data)
        .then((res) => {
          if (res.status) {
            this.dialogPackaging.show = false
            this.$store.commit('SNACKBAR', { show: true, text: this.dialogPackaging.data.id ? 'Updated!' : 'Created!' })
            if (parseInt(res.data.data.id)) {
              this.itemSelected = Object.assign({}, res.data.data)
            }
            this.PackagingLoad()
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong!' })
          }
        })
    },
    GetDates (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
</style>
